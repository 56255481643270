<template>
  <div>
    <v-container fluid>
      <v-card>
        <v-card-text>
          <div class="d-flex justify-space-between align-center">
            <h2 class="mr-4">Trip Gallery</h2>
            <v-btn color="primary" @click="showCreateForm = true">Create Trip Gallery</v-btn>
          </div>

          <!-- Dialog for Creating Gallery Entry -->
          <v-dialog v-model="showCreateForm" max-width="600">
            <v-card>
              <v-card-title>Create Trip Entry</v-card-title>
              <v-card-text>
                <!-- Trip Selection -->
                <v-autocomplete
                  v-model="selectedTrip"
                  :items="trips"
                  label="Select Trip"
                  item-text="name"
                  item-value="id"
                  clearable
                  outlined
                  :error-messages="tripErrors"
                  @input="tripErrors = []"
                  required
                ></v-autocomplete>

                <!-- Caption and Pictures -->
                <v-textarea
                v-model="caption"
                label="Caption"
                outlined
                :error-messages="captionError"
                @input="captionError = ''"
                required
              ></v-textarea>
              

                <v-file-input
                  v-model="pictures"
                  label="Upload or Capture Pictures"
                  accept="image/*;capture=camera"
                  multiple
                  outlined
                  :error-messages="picturesError"
                  @change="picturesError = ''"
                ></v-file-input>
              </v-card-text>
              <v-card-actions>
                <v-btn color="blue darken-1" @click="validateAndCreateEntry">Create</v-btn>
                <v-btn color="grey" @click="resetForm">Cancel</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <!-- Data Table for Displaying Gallery Entries -->
          <v-data-table
            :headers="headers"
            :items="galleryEntries"
            item-key="id"
            :search="search"
            :loading="isLoading"
            loading-text="Loading... Please wait"
          >
            <template v-slot:item.name="{ item }">{{ item.trip.name }}</template>
            <template v-slot:item.created_at="{ item }">
              {{ formatDate(item.created_at) }}
            </template>
                        <template v-slot:item.caption="{ item }">{{ item.caption }}</template>

            <!-- Actions Column -->
            <template v-slot:item.actions="{ item }">
              <v-icon small @click="viewEntry(item)">mdi-eye</v-icon>
              <v-icon small @click="confirmDelete(item)">mdi-delete</v-icon>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-container>

    <!-- Confirmation Dialog for Deleting Gallery Entry -->
    <v-dialog v-model="showDeleteDialog" max-width="290">
      <v-card>
        <v-card-title class="headline">Confirm Deletion</v-card-title>
        <v-card-text>Are you sure you want to delete this gallery entry?</v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="deleteEntry">Yes, Delete</v-btn>
          <v-btn color="grey" @click="showDeleteDialog = false">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Image Preview Dialog -->
    <v-dialog v-model="showImagePreview" max-width="800">
      <v-card>
        <v-card-title>
          <v-icon color="primary">mdi-file-image</v-icon>
          <span class="pl-2">Trip Gallery Images</span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col
              v-for="(image, index) in images"
              :key="index"
              cols="12" md="4" lg="3"
            >
              <div class="gallery-image">
                <v-avatar
                rounded
                size="120"
                @click="viewImage(image)"
              >
                <v-img
                  :src="getImageUrl(image)"
                  alt="Checklist Image"
                ></v-img>
              </v-avatar>
              </div>
              <p class="text-center font-weight-bold">Image {{ index + 1 }}</p>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn color="blue darken-1" @click="showImagePreview = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      trips: [],
      selectedTrip: null,
      caption: '',
      pictures: [],
      tripErrors: [],
      captionError: '',
      picturesError: '',
      galleryEntries: [],
      isLoading: false,
      search: "",
      headers: [
        { text: "Trip Name", value: "name" },
        { text: "Date", value: "created_at" },
        { text: "Caption", value: "caption" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      showCreateForm: false,
      showDeleteDialog: false,
      showImagePreview: false,
      entryToDelete: null,
      images: [],
      selectedEntry: null,
    };
  },
  mounted() {
    this.loadGalleryEntries();
    this.loadTrips();
  },
  methods: {
    loadGalleryEntries() {
      this.isLoading = true;
      axios
        .get(`/trip-galleries/all`)
        .then((response) => {
          this.galleryEntries = response.data;
        })
        .catch((error) => {
          console.error('Error loading gallery entries:', error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    loadTrips() {
      axios
        .get(`/vehicle-checklists/trips`)
        .then((response) => {
          this.trips = response.data.activeTrips;
        })
        .catch((error) => {
          console.error('Error loading trips:', error);
        });
    },
    validateAndCreateEntry() {
      let valid = true;

      if (!this.selectedTrip) {
        this.tripErrors.push('Please select a trip');
        valid = false;
      }
      if (!this.caption) {
        this.captionError = 'Please enter a caption';
        valid = false;
      }
      if (this.pictures.length === 0) {
        this.picturesError = 'Please upload or capture at least one picture';
        valid = false;
      }

      if (valid) {
        this.createEntry();
      }
    },
    createEntry() {
  const formData = new FormData();
  formData.append('trip_id', this.selectedTrip);
  formData.append('caption', this.caption);

  // Append pictures to the formData
  this.pictures.forEach((picture, index) => {
    formData.append(`pictures[${index}]`, picture);
  });

  // Sending the request to create a new gallery entry
  axios.post('/trip-galleries/create', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
  .then((response) => {
    // Display a success message
    this.$notify({ type: 'success', text: response.data.message });

    // Reload gallery entries
    this.loadGalleryEntries();

    // Reset the form and close the dialog
    this.resetForm();
    this.showCreateForm = false;
  })
  .catch((error) => {
    // Handle error and display error message
    if (error.response && error.response.data && error.response.data.errors) {
      // Display error messages from the server
      const serverErrors = error.response.data.errors;
      this.tripErrors = serverErrors.trip_id || [];
      this.captionError = serverErrors.caption || '';
      this.picturesError = serverErrors.pictures || '';
    } else {
      console.error('Error creating gallery entry:', error);
    }
  });
},

    resetForm() {
      this.selectedTrip = null;
      this.caption = '';
      this.pictures = [];
      this.tripErrors = [];
      this.captionError = '';
      this.picturesError = '';
    },
    confirmDelete(entry) {
      this.entryToDelete = entry;
      this.showDeleteDialog = true;
    },
    getImageUrl(image) {
      const path = image.remote_file_path;
      return `${process.env.VUE_APP_API_URL}/${path}`;
    },
    viewEntry(item) {
      this.selectedEntry = item;
      axios.get(`/trip-galleries/pictures/${item.id}`)
        .then((response) => {
          this.images = response.data.images;
          this.showImagePreview = true;
        })
        .catch((error) => {
          console.error('Error loading gallery images:', error);
        });
    },
    viewImage(image) {
      // Handle viewing image logic, if needed (e.g., opening in a larger view)
      console.log('Viewing image:', image);
    },
    formatDate(date) {
      return new Date(date).toLocaleString(); // This will include both date and time
    },
    deleteEntry() {
      if (this.entryToDelete) {
        axios
          .post(`/trip-galleries/delete/${this.entryToDelete.id}`)
          .then(() => {
            this.loadGalleryEntries();
            this.showDeleteDialog = false;
            this.entryToDelete = null;
          })
          .catch((error) => {
            console.error('Error deleting gallery entry:', error);
          });
      }
    },
  },
};
</script>

<style scoped>
/* Add any necessary styles */
</style>
